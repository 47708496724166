<template>
	<div>
		<h4 class="h3_title" style="text-align: center">{{ show_name }}</h4>
	
		<div style="margin: 30px auto 30px auto;width: 180px" v-if="dc_qrcode">
			<van-image :src="dc_qrcode" width="180px" />

			<p style="text-align: center;font-size: 12px;color: #999">收款二维码</p>
		</div>

		<div class="deposit-block">
			<div class="deposit-block-value">
				<p>收款地址</p>
				<p style="font-size: 16px;margin-top: 10px"><b>{{ dc_address }}</b></p>
			</div>

			<div class="deposit-block-function" @click="copy(dc_address)"><van-icon name="orders-o" size="21"/></div>

			<div style="clear:both"></div>
		</div>

		<van-cell-group inset>
			<van-cell title="入金金额" center clickable>
				<template #label>
					<van-field v-model="formItem.total" type="number" placeholder="请填写入金金额" />
				</template>

				<template #right-icon>
					<van-icon name="info-o" size="26"/>
				</template>
			</van-cell>
		</van-cell-group>

		<div class="common_block" v-show="max || min">
			<h4 style="margin: 10px 0">入金金额限制</h4>
			<div class="description">
				<p v-show="min">最低：{{ symbol }} {{min}}</p>
				<p v-show="max">最高：{{ symbol }} {{max}}</p>
			</div>
		</div>

		<div class="common_block">
			<van-button type="warning" block @click="nextStep()">下一步</van-button>
		</div>

		<div v-if="description" style="padding: 20px 16px">
			<p style="font-size: 13px;color: #666;margin-bottom: 15px">入金说明</p>
			<div v-html="description" style="color: #666"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'deposit_total',
		data() {
			return {
				formItem: {
					uid: "",
					token: "",
					currency_id: "",
					merchant_id: "",
					total: "",
				},

				show_name: "",
				description: "",
				symbol: "",

				dc_address: "",
				dc_qrcode: "",

				min: 0,
				max: 0,
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')

				return false
			} 

			var deposit_info = window.sessionStorage.getItem('deposit_info')

			if (!deposit_info) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}

			deposit_info = JSON.parse(deposit_info)

			if (deposit_info['currency_id'] == undefined || !deposit_info['currency_id']) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}
			
			if (deposit_info['merchant_id'] == undefined || !deposit_info['merchant_id']) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}

			this.formItem.token = this.$route.query.token
			this.formItem.currency_id = deposit_info['currency_id']
			this.formItem.merchant_id = deposit_info['merchant_id']

			this.symbol = deposit_info['symbol']

			this.init()
		},
		methods: {
			/*   
             * 复制数据
             */
			copy(data){
                if (window.JsObject != null) {
                    window.JsObject.copyDepositData(data);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_deposit_data": data});
                }
			},
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_deposit/merchantdc', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.formItem.uid = res.data.uid

						this.show_name = res.data.show_name
						this.description = res.data.description
						this.dc_qrcode = res.data.dc_qrcode
						this.dc_address = res.data.dc_address

						if (res.data.min) {
							this.min = parseFloat(res.data.min)
						}

						if (res.data.max) {
							this.max = parseFloat(res.data.max)
						}
					}
				})
			},
			nextStep() {
				if (this.formItem.total == '') {
					this.$dialog.alert({
						message: '请填写入金金额'
					})
					
					return false
				}

				var total = parseFloat(this.formItem.total)

				if (total <= 0) {
					this.$dialog.alert({
						message: '请填写入金金额',
					})
					
					return false
				}
				
				if (this.min != 0 && total < this.min) {
					this.$dialog.alert({
						message: '金额错误，最低入金：' + this.symbol + ' ' + this.min,
					})
					
					return false
				}
				
				if (this.max != 0 && total > this.max) {
					this.$dialog.alert({
						message: '金额错误，最高入金：' + this.symbol + ' ' + this.max,
					})
					
					return false
				}

				this.$toast.loading({
					message: '请稍等',
					forbidClick: true,
				})

				this.$axios({
					method: 'post',
					url: 'cash_deposit/totaldc', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {

						this.$toast.success({
							message: '入金已提交'
						})

						this.$router.replace('/deposit/info?token=' + this.formItem.token + '&id=' + this.formItem.uid)
		
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			}
		},
	}
</script>
